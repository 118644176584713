import { DOCUMENT } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { Request } from 'express';
import { lang } from 'interfaces';
import { BrowserService } from './browser.service';
import { FALLBACK_LANGUAGE } from './browser.token';
import { REQUEST } from './express.tokens';

@Injectable({
  providedIn: 'root',
})
/**
 * Provides an implementation of getting the user's default
 * language that works on the server by looking
 * at the request's header.
 */
export class BrowserServerService extends BrowserService {
  private req = inject<Request>(REQUEST, { optional: true });
  protected override document = inject<Document>(DOCUMENT);
  protected override fallbackLang = inject<lang>(FALLBACK_LANGUAGE);

  override getDefaultLanguage() {
    return super.getLanguageFromLocale(this.req?.headers?.['accept-language']);
  }

  override getOrigin() {
    if (!this.req) return 'http://localhost:4200';
    // host: domain name that the request is made to
    // x-forwarded-host: original host after proxy
    // https://stackoverflow.com/questions/58918274/difference-between-origin-and-x-forwarded-host-http-header
    const domain = this.req.get('x-forwarded-host') ?? this.req.hostname;
    return this.req.protocol + '://' + domain;
  }

  override getHost() {
    return this.req?.hostname;
  }
}
